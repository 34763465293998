import React , { useState , useEffect } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

//AOS
import AOS from "aos";
import "aos/dist/aos.css";

export default function LensSimulations() {

  const [coatingColor , setCoatingColor] =  useState("gold");
  const [driveWearOpt , setDriveWearOpt] = useState("BWS");

  const featureImgText = [
    {
      image: "https://oicweb-media.s3.ap-south-1.amazonaws.com/icons/icons-03.svg"
    },
    {
      image: "https://oicweb-media.s3.ap-south-1.amazonaws.com/icons/icons-04.svg"
    }
  ]
  const [featureIndex , setFeatureIndex] = useState(0);

  //AOS
  useEffect(() => { AOS.init({}); }, []);
  
  useEffect(() => {
    if(featureIndex == 1){
      setTimeout(() => setFeatureIndex(0),2950);
    }
    else{
      setTimeout(() => setFeatureIndex(featureIndex+1),2950);
    }

  })

  //Compare Images
  useEffect(() => {
    ImageComparisonFunction("compareOverlayUV");
    ImageComparisonFunction("compareOverlayDW");

    function ImageComparisonFunction(overlayImage){
      let images = document.getElementsByClassName(overlayImage);
      for(let i=0; i<images.length; i++){
        compareImages(images[i]);
      }

      function compareImages(images) {
        let slider, sliderLine , clicked = 0 , w , h;
        w = 1025;
        h = 455;
        
        images.style.width = (w/2) + "px";
        
        slider = document.createElement("DIV");
        slider.setAttribute("class" , "compareSlider");
        images.parentElement.insertBefore(slider,images);

        slider.style.top = (h/2) - (slider.offsetHeight/2) + "px";
        slider.style.left = (w/2) - (slider.offsetWidth/2) + "px"; 

        sliderLine = document.createElement("DIV");
        sliderLine.setAttribute("class" , "compareSliderLine");
        images.parentElement.insertBefore(sliderLine,slider);

        sliderLine.style.left = (w/2) - (slider.offsetWidth/3) + 21 + "px"; 

        slider.addEventListener("mousedown" , slideOn);
        window.addEventListener("mouseup" , slideOff);

        function slideOn(e) {
          e.preventDefault();

          clicked = 1;

          window.addEventListener("mousemove" , slideMove);
        }

        function slideMove(e){
          let pos;

          if(clicked === 0 ){
            return false;
          }

          pos = getCursorPos(e);
          if(pos<0){
            pos = 0;
          }
          if(pos>w){ 
            pos = w;
          }
          slide(pos);
        }

        function getCursorPos(e){
          let mouseX,side = 0;
          e = (e.changedTouches) ? e.changedTouches[0] : e;
          side = images.getBoundingClientRect();
          mouseX = e.pageX - side.left;
          mouseX = mouseX - window.pageXOffset;
          return mouseX;
        }

        function slide(move){
          images.style.width = move + "px";
          slider.style.left = images.offsetWidth - (slider.offsetWidth / 2 ) + "px";
          sliderLine.style.left = images.offsetWidth - 2 + "px";
        }

        function slideOff(){
          clicked = 0;
          setTimeout(() => {
            images.style.width = (w/2) + "px";
            slider.style.left = (w/2) - (slider.offsetWidth/2) + "px"; slider.style.left = (w/2) - (slider.offsetWidth/2) + "px";     
            sliderLine.style.left = (w/2) - (slider.offsetWidth/3) + 21 + "px"; 
          }, 5000);
        }
      }
    }
     
  },[])

  //Coating Color Changes
  useEffect(() => {

    let mCoatingImage = document.getElementById("mCoatingColor");

    if(coatingColor === "gold"){
      mCoatingImage.setAttribute("src" , require("../img/len-simulation/Gold.jpg"));
    }
    else if(coatingColor === "silver"){
      mCoatingImage.setAttribute("src" , require("../img/len-simulation/Silver.jpg"));
    }
    else{
      mCoatingImage.setAttribute("src" , require("../img/len-simulation/Blue.jpg"));
    }
  }, [coatingColor]);

  useEffect(() => {
    let dwImage = document.getElementById("dwImage");
    let dwImageOverlay = document.getElementById("dwImageOverlay");

    if(driveWearOpt === "BWS"){
      dwImage.setAttribute("src" , require("../img/len-simulation/drivewear/blurbehind.jpg"));
      dwImageOverlay.setAttribute("src" , require("../img/len-simulation/drivewear/behind.jpg"));
    }
    else if(driveWearOpt === "OO"){
      dwImage.setAttribute("src" , require("../img/len-simulation/drivewear/Brightlight.jpg"));
      dwImageOverlay.setAttribute("src" , require("../img/len-simulation/drivewear/Brightlight.jpg"));
    }
    else{
      dwImage.setAttribute("src" , require("../img/len-simulation/drivewear/Withoutovercast.jpg"));
      dwImageOverlay.setAttribute("src" , require("../img/len-simulation/drivewear/overcast.jpg"));
    }
    
  }, [driveWearOpt])

  return (
    <div>
      <Helmet>
        <title>
          Lens Simulation | OIC Lens simulation- Optical lens to enhance
          performance
        </title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta
          name="title"
          content="Lens Simulation | OIC Lens simulation- Optical lens to enhance performance"
        />
        <meta
          name="description"
          content="Customers can visualise the various lens effects put on the frame of their choice, as if it were on their face with the OIC Lens Simulator.Detailed information."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta
          property="og:description"
          content="Customers can visualise the various lens effects put on the frame of their choice, as if it were on their face with the OIC Lens Simulator.Detailed information."
        />
      </Helmet>
      <Layout>
        <div
          className="bannerImage"
          style={{
            backgroundImage: `url("https://oicweb-media.s3.ap-south-1.amazonaws.com/lens-simulation-banner.jpg")`,
          }}
        >
          <img src="" alt="Optical Lens to enhance performance" className="altImage" />
          <div className="container">
            <div className="row">
              <div className="col-md-4 bannerTextPadder">
                <h1>
                  <span>OPTICAL LENS</span> TO <span>ENHANCE</span> PERFORMANCE.
                </h1>
                <a href="https://lens.oicapps.com/" target="_blank" className="buttonStyleWhite hvr-forward">
                  Start Free Demo
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60" style={{ background: "#ECEDEF" }}>
          <div className="container" data-aos="fade-right" data-aos-duration="400">>
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8 text-center">
                <h2>
                  <span className="realistic-marker-highlight">
                    LENS SIMULATION
                  </span>
                </h2>
                <p>
                  Makes trying on frames about the lenses as well. With OiC Lens
                  Simulator, customers are able to visualize the various lens
                  effects mounted on the frame of their choice, as if it’s on
                  their face. This gives the customer with the thorough glasses
                  details and improves the shopping experience.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60">
          <div className="container" data-aos="fade-left" data-aos-duration="400" >
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>
                  <span className="realistic-marker-highlight">Features</span>
                </h2>
              </div>
              <div className="container IconBoxText">
                <div className="row featureglass">
                  <div className="featureglasses"><img src={require("../img/hp/Features_Glass.png")} /></div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsL" />
                    <div className="transitionRingsModifiedL" />
                    <div className="featureMarginCorrection IconBox blinkTransition">
                      <img className="transitionImages" ></img>
                      <p className="transitionText">
                        <br></br>
                        {/* <strong>Quicker <br />Decisions</strong> */}
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc featureMarginTextCorrection">
                      By eliminating decision paralysis, your customers end up
                      happier. Allows easy and best glasses for their
                      personality.
                    </p>
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsR" />
                    <div className="transitionRingsModifiedR" />
                    <div className="IconBox blinkTransition">
                      <img className="transitionImages transitionDirection"></img>
                      <p className="transitionText transitionDirection">
                        <br></br>
                        {/* <strong>Lens Enhancements Simulator</strong> */}
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc">
                      No matter your frame selection, you’ll want to find lenses
                      to fit your vision needs. It will enhance the lens
                      performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div className="padding60 blueBackground">
          <div className="container SuperWidth paddingBottomPercent" data-aos="fade-right" data-aos-duration="400">>
            <div className="row aligner">
              <div className="col-md-5">
                <h2 className="realistic-marker-highlight">Mirror Coating</h2>
                <p>
                  It is a modern coating that transforms glass and acrylic glass
                  into a completely unique replica-like impact.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-3"></div>
              <div className="col-md-4">
                {/* <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/lens-simulation-01.png"
                  className="img-responsive" alt="Mirror coating on a glass"
                ></img> */}

                <div className="mCoatingContainer">
                  <div><img id="mCoatingColor" src={require("../img/len-simulation/Gold.jpg")} alt="Mirror coating on a glass" /></div>
                  <div className="mCoatingContainerOptions">
                    <p>Select Coatings</p>
                    <ul>
                      <li onClick={() => setCoatingColor("gold")} className={coatingColor === "gold" && "coatingActive"}>
                        Gold
                      </li>
                      <li onClick={() => setCoatingColor("silver")} className={coatingColor === "silver" && "coatingActive"}>
                        Silver
                      </li>
                      <li onClick={() => setCoatingColor("blue")} className={coatingColor === "blue" && "coatingActive"}>
                        Blue
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60 ">
          <div className="container" data-aos="fade-left" data-aos-duration="400">>
            <div className="row ">
              <div className="col-md-12  text-center">
                <h2>
                  <span className="realistic-marker-highlight">
                    UV Max Technology
                  </span>
                </h2>
              </div>
              <div className="col-md-12 text-center">
                <p>
                  UVMAX Lens Coating, which is a quick curing, long lasting
                  optical coating presenting extraordinary adhesion with low
                  viscosity.
                </p>

                {/* <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/lens-simulation-04.png"
                  className="img-responsive" alt="OIC UV max technology on a glass"
                ></img> */}

                <div className="compareContainCaster">
                  <div className="compareContainer">
                    {/* <div id="compHeadingL" className="compareHeadingL"><p>With Uv Max</p></div>
                    <div id="compHeadingR" className="compareHeadingR"><p>Without Uv Max</p></div> */}
                    <div className="compareImages"><img width="1025" src={require("../img/len-simulation/standard.jpg")} /></div>

                    <div className="compareImages compareOverlayUV"><img width="1025" src={require("../img/len-simulation/UV.jpg")} /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60 blueBackground">
          <div className="container" data-aos="fade-right" data-aos-duration="400" >
            <div className="row aligner">
              <div className="col-md-7 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/lens-simulation-03.png"
                  className="img-responsive" alt="OIC single vision video on a laptop"
                ></img>
              </div>
              <div className="col-md-5">
                <p style={{ color: "#03A5A5" }}>OVERALL FUNCTION</p>
                <h2 className="realistic-marker-highlight">INCREASED SALES</h2>
                <p>
                  The fact client is able to see exactly what they’ll look like
                  on your prescription, customers are much more likely to
                  purchase without delay. mission
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Add Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-7 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/lens-simulation-03.png"
                  className="img-responsive" alt="OIC single vision video on a laptop"
                ></img>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="padding60 ">
          <div className="container" data-aos="fade-left" data-aos-duration="400">>
            <div className="row ">
              <div className="col-md-12  text-center">
                <h2>
                  <span className="realistic-marker-highlight">
                    Basic Coatings
                  </span>
                </h2>
              </div>
              <div className="col-md-12 text-center">
                <p>
                  While nothing can render scratch-proof optical lenses, an
                  anti-scratch coating makes the surface much tougher and more
                  durable.
                </p>

                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/lens-simulation-02.png"
                  className="img-responsive" alt="scratch-proof optical lenses"
                ></img>
              </div>
            </div>
          </div>
        </div> */}

        <div className="padding60 ">
          <div className="container" data-aos="fade-left" data-aos-duration="400">>
            <div className="row ">
              <div className="col-md-12  text-center">
                <h2>
                  <span className="realistic-marker-highlight">
                    Drive Wear
                  </span>
                </h2>
              </div>
              <div className="col-md-12 text-center">
                <p>
                  Drivewear lenses feature a high efficiency polarizer never before found in such a high contrast, light color. This 
                  polarized film is active in every lighting condition to block blinding glare and improve visual acuity.
                </p>

                <div className="compareContainCaster">
                  <div className="compareContainer">
                    <div className="compareImages"><img width="1025" id="dwImage" src={require("../img/len-simulation/drivewear/blurbehind.jpg")} /></div>

                    <div className="compareImages compareOverlayDW"><img width="1025" id="dwImageOverlay" src={require("../img/len-simulation/drivewear/behind.jpg")} /></div>

                    <div className="compareOptions">

                      <ul>

                        <li className={driveWearOpt === "BWS" && "compareOptionsActive borderRadiusL"} onClick={() => setDriveWearOpt("BWS")} >
                          Behind Wind Shield
                        </li>

                        <li className={driveWearOpt === "OO" && "compareOptionsActive"} onClick={() => setDriveWearOpt("OO")} >
                          Overcase Outside
                        </li>

                        <li className={driveWearOpt === "BL" && "compareOptionsActive borderRadiusR"} onClick={() => setDriveWearOpt("BL")} >
                          Bright Light
                        </li>

                      </ul>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Layout>
    </div>
  );
}
